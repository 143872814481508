import styled, { css } from 'styled-components'
import { rem } from '../../utils/common'
import {merchantThemes} from "../Variable";

export const AccordionList = styled.div`
	
`
export const Accordion = styled.div`
	position: relative;
`
export const Header = styled.div`
	min-height: ${rem(32)};
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	padding: ${rem(5, 0)};
	z-index: 5;
	background-color: ${({ theme }) => theme.color.secondary};
`
export const Title = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	font-size: ${rem(18)};
	line-height: ${rem(20)};
	font-weight: 600;
	cursor: pointer;
	
	${p => p.theme.name === merchantThemes.marysia && css`
      font-weight: normal;
    `}
    ${p => p.theme.name === merchantThemes.woodwood && css`
      font-weight: normal;
    `}
    ${p => p.theme.name === merchantThemes.aycane && css`
      font-weight: normal;
    `}
	${p => p.theme.name === merchantThemes.bymalina && css`
      font-size: ${rem(14)};
      font-style: italic;
    `}
	
`
export const AccordionToggle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.3s;
	margin-right: ${rem(10)};
	svg{
		fill: ${({ theme }) => theme.color.primary};
		${(p) =>
			p.isOpen &&
			css`
			fill: #43484D;
		`}
	}
`
export const Editor = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: ${rem(8, 0)};
	img {
		max-width: 100%;
		margin-bottom: ${rem(16)};
	}
	p {
		font-size: ${rem(14)};
		line-height: ${rem(22)};
		color: ${({ theme }) => theme.color.grayThree};
	}
	
	${p => p.theme.name === merchantThemes.bymalina && css`
		font-size: ${rem(14)};
		font-style: italic;
	`}
`
export const Collapse = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s;
	padding-left: ${rem(34)};

	${(p) =>
		p.isOpen &&
		css`
			max-height: 500vh;
		`}
`
