import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'
import { reducer as rootSlices } from './root-slices'
import { reducer as popup } from "../components/Modal/slices";
import { reducer as main } from "../containers/Main/slices";
import { reducer as multimarketbox } from "../components/MultiMarketComboBox/slices";

export default function createReducer(injectedReducers = {}) {
	const appReducer = combineReducers({
		router: connectRouter(history),
		root: rootSlices,
		popup,
		main,
		multimarketbox,
		...injectedReducers,
	})

	const rootReducer = (state, action) => {
		// if (action.type === 'auth/logout') {
		// 	state = undefined
		// }
		return appReducer(state, action)
	}
	return rootReducer
}
