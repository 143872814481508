import styled, { css } from 'styled-components'
import { device, merchantThemes } from '../../../../components/Variable'
import { rem } from '../../../../utils/common'

export const Bg = styled.div`
	background-color: ${({ theme }) => theme.color.secondary};
	box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
	padding: 0;
	width: 100%;
	border-radius: ${rem(8)};
	position: relative;
	z-index: 4;
	transition: top 0.5s;
	top: 0;
	
	${p => p.theme.name === merchantThemes.woodwood && css`
     box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
  `}
`
export const FormStyle = styled.form`
	//border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};

	width: 100%;
	display: block;
	padding: ${rem(40, 32)};
	top: ${rem(80)};
	z-index: 5;
	@media (${device.tablet}) {
		position: relative;
		top: 0;
		padding: ${rem(22)};
	}
	@media (${device.mobileL}) {
		padding: ${rem(26, 16, 16)};
	}

`
export const FormHeader = styled.div`
	position: relative;
`
export const FormHeaderItem = styled.div`
	&:first-child {
		width: ${rem(200)};
		position: relative;
		z-index: 2;
		margin-bottom: ${rem(30)};
	}
	&:last-child {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
	}
`
export const FormTitle = styled.div`
	text-align: center;
	font-size: ${rem(26)};
	margin-bottom: ${rem(40)};
	
	${p => p.theme.name === merchantThemes.bymalina && css`
        font-size: ${rem(25.74)};
        width: ${rem(225)};
        margin: 0 auto ${rem(20)} auto;
        line-height: ${rem(27)};
    `}
	
	@media (${device.tablet}) {
		margin-bottom: ${rem(20)};
	}
	@media (${device.mobileL}) {
		margin-bottom: ${rem(26)};
	}
`
export const FormTermsHolder = styled.div`
	text-align: center;
	font-style: italic;
	@media (${device.tablet}) {
		margin-bottom: ${rem(15)};
	}
	@media (${device.mobileL}) {
		margin-bottom: 0;
	}
`
export const Description = styled.p`
	font-size: ${rem(14)};
	line-height: ${rem(20)};
	text-align: center;
	max-width: ${rem(370)};
	margin: 0 auto ${rem(15)};
`
export const LinkUnderline = styled.span`
	//font-weight: 600;
	font-size: ${rem(14)};
	line-height: ${rem(18)};
	//display: inline-block;
	text-decoration: underline;
	cursor: pointer;
	transition: color 0.3s;
	margin-bottom: ${rem(5)};
	&:hover {
		color: ${({ theme }) => theme.color.primary};
	}
	${(p) =>
		!p.orderNum &&
		p.orderLink &&
		css`
			margin-bottom: ${rem(30)};
		`}
`

export const FormSocialViewBack = styled.div`
	position: absolute;
	width: ${rem(38)};
	height: ${rem(38)};
	border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
	background: ${({theme}) => theme.color.grayTwo};
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
	opacity: 0.7;
	transform: rotate(90deg);
	
	> svg {
	  fill: ${({theme}) => theme.color.blackOne};
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  margin-top: ${rem(-4)};
	  margin-left: ${rem(-8)};
	}
	
	&:hover {
      opacity: 1;
	}
`