import React, {} from 'react'
import {FooterWrapper, FooterLogoWrapper, FooterLinksWrapper, FooterLink} from "./style";
import {Icon} from "../../../../components/Icon";

const Footer = ({currentTheme}) => {
    return (
        <FooterWrapper>
            <FooterLogoWrapper>
                <FooterLink href="https://returnado.com/" target="_blank">
                    <Icon component={'LogoReturnado'} />
                </FooterLink>
            </FooterLogoWrapper>
            {
                !!(currentTheme.name === "marysia") && <FooterLinksWrapper>
                    <FooterLink href="mailto:customerservice@marysia.com">Contact Us</FooterLink> | <FooterLink target="_blank" href="https://marysia.com/pages/service">View full policy</FooterLink>
                </FooterLinksWrapper>
            }

        </FooterWrapper>
    )
}
export default Footer
