import React, {useMemo, useEffect} from 'react'
import {FormStyle, FormHeader, FormTitle, LinkUnderline, Bg, FormTermsHolder, FormSocialViewBack} from './style'
import {useForm} from 'react-hook-form'
import Input from '../../../../components/Form/Input'
import {ButtonFull} from '../../../../components/Form/Button'
import Checkbox from '../../../../components/Form/Checkbox'
import * as yup from 'yup'
import {useYupValidationResolver} from '../../../../utils/common'
import {FormattedMessage, useIntl} from 'react-intl'
import {showPopup} from "../../../../components/Modal/slices";
import {useDispatch, useSelector} from "react-redux";
import {merchantThemes} from "../../../../components/Variable";
import {getEmail} from "../../thunk";
import {Icon} from "../../../../components/Icon";
import {toggleSocialView} from "../../slices";

const Form = ({
              openWidget = () => {
              },
              orderId = '',
              email = '',
              onClickStatus = () => {},
              currentTheme = {},
              returnadoToken = null,
              isSocialView = null
          }) => {
    const intl = useIntl()
    //const [isOrderVisible, setOrderVisible] = useState(true)
    const dispatch = useDispatch()

    //const {isVisible} = useSelector((state) => state.popup);
    const {terms, policy} = useSelector((state) => state.main);
    const {locale, shopId} = useSelector((state) => state.root);

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .email('Invalid email address')
                    .required(intl.formatMessage({
                        id: 'standalone.login.form.field.required',
                        defaultMessage: 'required',
                    }))
                    .trim(),
                orderId: yup.string().trim(),
                termAndCond: yup
                    .boolean()
                    .oneOf([true], intl.formatMessage({
                        id: 'standalone.login.form.checkbox.required',
                        defaultMessage: 'You need to accept the terms before being able to continue',
                    })),
            }),
        [intl]
    )


    const openPolicyPopupWindow = (e) => {
        e.preventDefault();
        dispatch(showPopup({
            type: 'policy',
            data: policy
        }))
    }
    const openTermsPopupWindow = (e) => {
        e.preventDefault();
        dispatch(showPopup({
            type: 'terms',
            data: terms
        }))
    }

    const {
        register,
        handleSubmit,
        errors,
        watch,
        formState: {},
        clearErrors
    } = useForm({
        resolver: useYupValidationResolver(validationSchema),
        defaultValues: {
            email: email || '', //mmallardsmith@me.com
            orderId: orderId || '', //3685177327701
        },
    })

    useEffect(() => {
        clearErrors()
    }, [locale, clearErrors])

    const onSubmit = (data) => {
        let {email, orderId, termAndCond} = data
        if (email && orderId && termAndCond) {
            if(!data.language) {
                data.language = locale
            }
            openWidget(data)
        }
        if (email && termAndCond && !orderId) {
            dispatch(getEmail({
                email: email.replace(/\+/g, '%2B'),
                language: locale,
                shopId: shopId
            }))
        }

    }

    const onTokenLogin = () => {
        openWidget({
            returnadoToken: returnadoToken
        })
    }

    return (
        <Bg>
            <FormStyle onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormHeader>
                    {
                        isSocialView === false || isSocialView === true
                            ?
                            <FormSocialViewBack onClick={()=>{
                                dispatch(toggleSocialView())
                            }}>
                                <Icon component={'arrowDown'} />
                            </FormSocialViewBack>
                            :
                            ''
                    }

                    <FormTitle>
                        {
                            currentTheme && currentTheme.name === merchantThemes.woodwood
                                ?
                                <FormattedMessage
                                    id='login.intro.not.satisfied.header'
                                    defaultMessage='Not satisfied with your purchase?'
                                />
                                :
                                <FormattedMessage
                                    id='login.intro.header'
                                    defaultMessage='Login with email'
                                />
                        }
                    </FormTitle>
                </FormHeader>
                <Input
                    type='email'
                    name='email'
                    placeholder={intl.formatMessage({
                        id: 'login.form.email.placeholder',
                        defaultMessage: 'email',
                    })}
                    register={register}
                    isCustomized={true}
                    error={errors.email}
                    className='mb-3'
                />
                <Input
                    type='text'
                    name='orderId'
                    placeholder={intl.formatMessage({
                        id: 'login.form.order.placeholder',
                        defaultMessage: 'order number',
                    })}
                    register={register}
                    isCustomized={true}
                    error={errors.orderId}
                    className='mb-4'
                />
                {
                    !watch('email') && !watch('orderId') && returnadoToken
                        ?
                        <ButtonFull
                            w100
                            type='button'
                            className='mb-4'
                            iconPosition={'left'}
                            onClick={onTokenLogin}
                        >
                            <FormattedMessage
                                id='login.form.login.token.button.text'
                                defaultMessage='VIEW ORDER BY TOKEN'
                            />
                        </ButtonFull>
                        :
                        <ButtonFull
                            w100
                            type='submit'
                            className='mb-4'
                            iconPosition={'left'}
                        >
                            {watch('email') && !watch('orderId') ? (
                                <FormattedMessage
                                    id='login.form.login.button.onlyEmail'
                                    defaultMessage='E-MAIL ME LINK'
                                />
                            ) : (
                                <FormattedMessage
                                    id='login.form.login.button.text'
                                    defaultMessage='VIEW ORDER'
                                />
                            )}
                        </ButtonFull>
                }
                <FormTermsHolder>
                    <Checkbox
                        register={register}
                        name='termAndCond'
                        error={errors.termAndCond}
                    >
                        <FormattedMessage
                            id='login.form.termsAndConditions'
                            defaultMessage="I accept Returnado's"
                        />{' '}
                        {!!(terms && currentTheme && currentTheme.name !== merchantThemes.marysia) && (
                            <LinkUnderline onClick={openTermsPopupWindow}>
                                <FormattedMessage
                                    id='login.form.termsAndConditions.link'
                                    defaultMessage='Terms and Conditions'
                                />
                            </LinkUnderline>)}{' '}
                        {(!!(currentTheme && currentTheme.name !== merchantThemes.marysia) && <FormattedMessage
                            id='login.form.termsAndConditions.label'
                            defaultMessage='and'
                        />)}{' '}
                        {!!policy && (<LinkUnderline onClick={openPolicyPopupWindow}>
                            <FormattedMessage
                                id='login.form.privacyPolicy.link'
                                defaultMessage='Privacy Policy'
                            />
                        </LinkUnderline>)}
                    </Checkbox>
                </FormTermsHolder>
            </FormStyle>
        </Bg>
    )
}
export default Form
