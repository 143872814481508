import styled, {css} from 'styled-components'
import {rem} from '../../utils/common'
import woodWoodBg from '../../assets/wood_wood_bg.png'
import doucheBagsBg from '../../assets/douchebags_background.png'
import aycaneBg from '../../assets/aycane_bg.png'
import byMalinaBg from '../../assets/bymalina_bg.png'
import royalDesignBg from '../../assets/royal_design_bg.png'
import eightyEightyFourtyEighty from '../../assets/8848_bg.png'
import henryLloydBg from'../../assets/henry_lloyd_bg.png'
import chimiBg from'../../assets/chimi_bg.png'
import mustoBg from'../../assets/musto_bg.png'
import {device, merchantThemes} from '../../components/Variable'

export const MainBackground = styled.div`
  background-color: ${({theme}) => theme.color.secondary};
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover;
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  
  .scrollbar-container{
    padding: 0 0 ${100} 0;
  }
  
  ${p => p.theme.name === merchantThemes.woodwood && css`
     background-image: url("${woodWoodBg}");
  `}
  
  ${p => p.theme.name === merchantThemes.douchebags && css`
     background-image: url("${doucheBagsBg}");
  `}
  
  ${p => p.theme.name === merchantThemes.aycane && css`
     background-image: url("${aycaneBg}");
  `}
  
  ${p => p.theme.name === merchantThemes.bymalina && css`
     background-image: url("${byMalinaBg}");
  `}

  ${p => p.theme.name === merchantThemes.royal_design && css`
     background-image: url("${royalDesignBg}");
  `}
  
  ${p => p.theme.name === merchantThemes['8848'] && css`
    background-image: url("${eightyEightyFourtyEighty}");
  `}
  
  ${p => p.theme.name === merchantThemes.henrylloyd && css`
    background-image: url("${henryLloydBg}");
  `}
  ${p => p.theme.name === merchantThemes.chimi && css`
    background-image: url("${chimiBg}");
  `}
  ${p => p.theme.name === merchantThemes.musto && css`
    background-image: url("${mustoBg}");
  `}

  ${p => p.isOpen && css`
    background-position: center bottom ${rem(-200)}, center;
  `}
`

export const MainContent = styled.div`
  max-width: ${rem(589)};
  padding-bottom: ${rem(20)};
  margin: 0 auto;
  
  @media (${device.tablet}) {
    width: 90%;
    margin-top: 0;
  }
`
export const MainContentLanguageWrapper = styled.div`
  position: absolute;
  display: flex;
  left: ${rem(45)};
  top: ${rem(15)};
  width: 100%;
  z-index: 10;
  
  @media (${device.tablet}) {
    position: relative;
    display: flex;
    left: auto;
    top: auto;
    width: 100%;
    margin: ${rem(16)} auto ${rem(5)} auto;
  }
  
  @media (${device.mobileL}) {
    justify-content: space-between;
    
    > div:first-child {
      margin: 0;
    }
    > div {
      width: 50%;
    }
  }
`

export const MainLogoImg = styled.img`
  display: block;
  margin: ${rem(58)} auto ${rem(40)} auto;
  width: 100%;
  max-width: ${rem(200)};
  transition: all .2s;
  opacity: 1;


  ${p => p.theme.name === merchantThemes.bymalina && css`
    margin: ${rem(78)} auto ${rem(40)} auto;
    max-width: ${rem(450)};
  `}

  ${p => p.theme.name === merchantThemes.royal_design && css`
    margin: ${rem(78)} auto ${rem(40)} auto;
    max-width: ${rem(380)};
  `}

  @media (${device.tablet}) {
    width: 90%;
    margin: ${rem(20)} auto;
  }
  @media (${device.mobileL}) {
    width: ${rem(140)};
    
    ${p => p.theme.name === merchantThemes.bymalina && css`
        width: ${rem(240)};
    `}
    
    ${p => p.theme.name === merchantThemes.royal_design && css`
        width: ${rem(240)};
    `}
  }

  ${p => p.isOpen && css`
    opacity: 0;
  `}
`

export const MainLogoImgWrapper = styled.div`
    display: block;
    max-width: ${rem(416)};
    margin: ${rem(58)} auto ${rem(40)} auto;
    width: 100%;
    max-width: ${rem(200)};
    transition: all .2s;
    opacity: 1;

    @media (${device.tablet}) {
        width: 90%;
        margin: ${rem(25)} auto ${rem(10)} auto;
    }
    
    ${p => p.theme.name === merchantThemes.woodwood && css`
      max-width: ${rem(400)};
      background-color: ${({theme}) => theme.color.secondary};
      box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
      border-radius: ${rem(8)};
      padding: ${rem(2)} ${rem(10)};
    `}
    
    ${p => p.isOpen && css`
      opacity: 0;
    `}
    
    ${p => p.noImage && css`
      min-height: ${rem(53)};
      visibility: hidden;
          svg {
            display: none;
          }
    `}
`

export const Title = styled.div`
  text-align: center;
  cursor: pointer;
  position: relative;
  font-size: ${rem(20)};
  margin-bottom: ${rem(15)};
  transition: margin-bottom 0.5s;
 
    ${p => p.theme.name === merchantThemes.bymalina && css`
        font-size: ${rem(17.12)};
        margin-bottom: ${rem(0)};
        line-height: ${rem(26)};
    `}
  
  @media (${device.tablet}) {
    font-size: ${rem(15)};
    ${p => !p.isOpen && css`
      margin-bottom: ${rem(0)};
    `}
    
    ${p => p.theme.name === merchantThemes.bymalina && css`
         line-height: ${rem(20)};
    `}
  }

  svg {
    width: 100%;
  
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}
  }

  ${p => p.theme.name === merchantThemes.woodwood && css`
    font-weight: 600;
  `}
`

export const ArrowControlWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    transition: all .3s;
    transform: rotate(0deg);
    border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
    width: ${rem(24)};
    height: ${rem(24)};
    border-radius: ${rem(5)};
    z-index: 10;
    padding: ${rem(5)};
    
    @media (${device.tablet}) {
        width: ${rem(20)};
        height: ${rem(20)};
        right: ${rem(0)};
        top: 0;
    }
    
    ${p => p.isOpen && css`
      transform: rotate(-180deg);
    `}
    
    ${p => p.theme.name === merchantThemes.marysia && css`
      border: ${rem(1)} solid ${({theme}) => theme.color.grayFour};
      border-radius: ${rem(0)};
      
      svg {
        fill: ${({theme}) => theme.color.grayFour};
      }
    `}
    
    
`

export const MainText = styled.div`
  background-color: ${({theme}) => theme.color.secondary};
  box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
  padding: ${rem(15, 32, 32, 32)};
  width: 100%;
  border-radius: ${rem(8)};
  margin-top: ${rem(20)};
  position: relative;
  z-index: 3;
  max-height: ${rem(58)};
  overflow: hidden;
  transition: all .3s;
  @media (${device.tablet}) {
    max-height: ${rem(50)};
    padding: ${rem(10, 10, 10, 10)};
  }
  ${p => p.isOpen && css`
    max-height: 200vh !important;
  `}
  
  ${p => p.theme.name === merchantThemes.woodwood && css`
     box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
     border-radius: ${rem(6)};
  `}
  
  ${p => p.theme.name === merchantThemes.aycane && css`
     border-radius: ${rem(6)};
  `}
  
  ${p => p.theme.name === merchantThemes.marysia && css`
     display: none;
  `}
`
export const BoxAnimation = styled.div`
  position: relative;
  margin-bottom: ${rem(60)};
  
  @media (${device.tablet}) {
    margin-bottom: ${rem(60)};
  }
`
export const AccordionWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
  max-height: 0;
  
  ${p => p.isOpen && css`
    max-height: 400vh;
  `}
`

export const EmptyLogoWrapper = styled.div`
  height: ${rem(120)};
  @media (${device.tablet}) {
    height: ${rem(35)};
  }
`