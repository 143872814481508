import styled from 'styled-components'
import { rem } from '../../utils/common'
import { device } from '../Variable'
import planetIcon from '../../assets/icons/markets-planet-icon.png'

export const MultipleComboBoxSelectStyle = styled.div`
    position: relative;
	display: block;
	max-width: ${rem(160)};
	width: 100%;
	margin-right: ${rem(36)};
	
	@media (${device.mobileL}) {
	    position: relative;
	    max-width: ${rem(149)};
		width: 50%;
		z-index: 6;
		padding: 0;
	}
	.react-select {
		&__control {
			width: 100%;
			max-height: ${rem(32)};
			@media (${device.mobileL}) {
				width: 100%;
				margin: 0 auto;
			}
		}
		&__indicators {
			cursor: pointer;
			height: 100%;
		}
		&__indicator-separator {
			display: none;
		}
		&__dropdown-indicator {
		}
		&__value-container {
		  height: 100%;
		}
		&__single-value {
		    white-space: nowrap;
			@media (${device.mobileL}) {
				font-size: ${rem(14)};
			}
		}

		&__menu {
		  margin: ${rem(1, 0,0,0)};
		  border-radius: 0;
		  min-width: ${rem(140)};
		}

		&__option {
		}
	}
`

export const MultipleComboBoxSelectStyleIcon = styled.div`
    position: absolute;
    background: url(${planetIcon}) no-repeat;
    background-size: contain;
    width: ${rem(26)};
    height: ${rem(24)};
    left: ${rem(-23)};
    top: 50%;
    margin: ${rem(-12)};
    
    @media (${device.mobileL}) {
	    display: none;
	}
`