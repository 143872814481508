import { createGlobalStyle, css } from 'styled-components'
import { rem } from '../../utils/common'
import { fonts, merchantThemes } from '../../components/Variable'

export const GlobalStyle = createGlobalStyle`
	*,
	*::before,
	*::after {
		box-sizing: border-box;
		outline: none;
		box-shadow: none;
		border: 0;
		margin: 0;
		padding: 0;
	}
	html {
        font-size: 16px;
    }
    body {
        font-size: ${rem(16)};
        font-family: ${fonts.futura};
        color: ${({ theme }) => theme.color.blackOne};
		background-color: ${({ theme }) => theme.color.grayTwo};
		${(p) => p.theme.name === merchantThemes.marysia && css`
            font-family: ${fonts.futura};
        `}
        ${(p) => p.theme.name === merchantThemes.woodwood && css`
            font-family: ${fonts.WWBeta8Regular};
        `}
        ${(p) => p.theme.name === merchantThemes.aycane && css`
            font-family: ${fonts.IBMPlex};
        `}
        ${(p) => p.theme.name === merchantThemes.bymalina && css`
            font-family: ${fonts.AvenirBook};
        `}
        ${(p) => p.theme.name === merchantThemes.royal_design && css`
            font-family: ${fonts.openSans};
        `}
        ${(p) => p.theme.name === merchantThemes.henrylloyd && css`
			font-family: ${fonts.Gotham};
		`}
		${(p) => p.theme.name === merchantThemes.chimi && css`
			font-family: ${fonts.AmericaExpandedRegular};
		`}
		${(p) => p.theme.name === merchantThemes.musto && css`
			font-family: ${fonts.openSans};
		`}
    }
    html,
    body {
        position: relative;
        height: 100%;
		overflow: hidden;
    }
    input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input:-internal-autofill-selected,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
	  background-color: transparent !important; 
	  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
	}
	a{
		color: ${({ theme }) => theme.color.grayThree};
		transition: color .3s;
		&:hover{
			color: ${({ theme }) => theme.color.primary};
		}
	}
	svg{
		display: block;
		transition: all .3s;
	}
	#root{
		position: relative;
		height: 100%;
		&.overflow-hidden{
			overflow: hidden;
		}
	}
	.flex{
		display: flex;
		flex-flow: row wrap;
	}
	.container{
		@media (min-width: 576px){
			max-width: 100%;
		}
	}
`
