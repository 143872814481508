import styled, {css} from 'styled-components'
import {device, merchantThemes} from '../../../../components/Variable'
import {rem} from "../../../../utils/common";

export const FooterWrapper = styled.footer`
    box-shadow: 0 0 1px ${({theme}) => theme.color.blackOne};
    background-color: ${({theme}) => theme.color.footerBg};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    text-align: center;
	
    ${p => p.theme.name === merchantThemes.douchebags && css`
       box-shadow: none;
       z-index: 1;
    `}
`

export const FooterLogoWrapper = styled.div`
    padding: ${rem(10)} ${rem(0)} ${rem(20)};
    max-width: ${rem(150)};
    margin: 0 auto;
    
    svg {
      width: 100%;
      fill: ${({theme}) => theme.color.headerLogo}
    }
    
    ${p => p.theme.name === merchantThemes.marysia && css`
      display: none;
    `}
    
    ${p => p.theme.name === merchantThemes.douchebags && css`
       svg {
          width: 100%;
          fill: ${({theme}) => theme.color.primary}
        }
    `}
    
    @media (${device.tablet}) {
		padding: ${rem(5)} ${rem(0)};
		max-width: ${rem(100)};
	}
	
`

export const FooterLinksWrapper = styled.div`
    display: none;
    
    ${p => p.theme.name === merchantThemes.marysia && css`
      display: block;
      padding: ${rem(21, 0)};
    `}
`

export const FooterLink = styled.a`
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
`