import styled, { css } from 'styled-components'
import { device, merchantThemes } from '../../../../components/Variable'
import { rem } from '../../../../utils/common'

export const LoggedInWrapper = styled.div`
	background-color: ${({ theme }) => theme.color.secondary};
	box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
	padding: ${rem(40, 25, 30)};
	width: 100%;
	border-radius: ${rem(8)};
	position: relative;
	z-index: 4;
	transition: top 0.5s;
	top: 0;
	
	${p => p.theme.name === merchantThemes.woodwood && css`
     box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
    `}
`

export const LoggedInHeader = styled.div`
	position: relative;
`
export const LoggedInLogout = styled.div`
	position: absolute;
	width: ${rem(38)};
	height: ${rem(38)};
	border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
	background: ${({theme}) => theme.color.grayTwo};
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
	opacity: 0.7;
	
	> svg {
	  fill: ${({theme}) => theme.color.redOne};
	  position: absolute;
	  width: ${rem(20)};
	  height: ${rem(20)};
	  top: 50%;
	  left: 50%;
	  margin-top: ${rem(-10)};
	  margin-left: ${rem(-9)};
	}
	
	&:hover {
      opacity: 1;
	}
`
export const LoggedInTitle = styled.div`
	text-align: center;
	font-size: ${rem(26)};
	margin-bottom: ${rem(40)};
	padding: 0 ${rem(40)};
	position: relative;
	@media (${device.tablet}) {
		margin-bottom: ${rem(20)};
	}
	@media (${device.mobileL}) {
		font-size: ${rem(22)};
		margin-bottom: ${rem(26)};
		padding: 0 0 0 ${rem(40)};
	}
	
	${p => p.theme.name === merchantThemes.bymalina && css`
        font-size: ${rem(25.74)};
        width: ${rem(225)};
        margin: 0 auto ${rem(20)} auto;
        line-height: ${rem(27)};
		padding: 0 0 0 0 !important;
		@media (${device.mobileM}) {
			width: ${rem(200)};
			margin-left: ${rem(52)}
		}
    `}
`
export const LoggedInBody = styled.div`
	border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
	background: ${({theme}) => theme.color.grayTwo};
	border-radius: ${rem(6)};
	padding: ${rem(15)};
	
	.logged-in-control {
	  margin-bottom: ${rem(15)} !important;
	}
`
export const LoggedInP = styled.p`
	padding: ${rem(25, 0, 35)};
	text-align: center;
`