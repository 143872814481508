import React, {useEffect, useRef, useState} from 'react'
import LanguageSelect from '../../../components/LanguageSelect'
import { useDispatch, useSelector } from 'react-redux'
import { langList, SS } from '../../../utils/common'
import { getLocale } from '../../../store/root-thunk'
import { setLanguage } from "../../../store/root-slices";
import { getTermsReq } from "../../../containers/Main/thunk";
import { showMarketControl } from "../../MultiMarketComboBox/slices";
import _ from 'lodash'

const getShopLanguage = (options, locale) => {
	return _.find(options, (i) => i.value === locale)
}

const LanguageSwitcher = ({isMarketSwitcherDisabled, isTokenLogin, marketFlag}) => {
	const dispatch = useDispatch()
	const { shopId, locale, messages } = useSelector((state) => state.root)
	const options = useSelector(
		(state) => state.root.shopProperties['widget.supported.languages']
	)
	const shopLanguage = useSelector(
		(state) => state.root.shopProperties['shop.language']
	)
	const isLanguageSwitcherDisabled = useSelector(
		(state) => state.root.shopProperties['widget.lang.select.disabled']
	)
	let selectWrapperRef= useRef()
	const [langListOptions, setLangListOptions] = useState([])

	useEffect(()=>{
		setLangListOptions(langList(options))
	}, [options])

	let onMenuOpen = () => {
		/*if(!isMarketSwitcherVisible && isMarketOptions) {
			setTimeout(()=>{
				let a = document.createElement('div');
				a.textContent = changeMarketTitle;
				a.classList.add('market-change-switcher');
				a.addEventListener('click', ()=>{
					dispatch(showMarketControl())
				})
				a.addEventListener('touchstart', ()=>{
					dispatch(showMarketControl())
				})

				selectWrapperRef.current.querySelector('.react-select__menu').prepend(a)
			},50)
		}*/
	}

	/**
	 * handlerLanguageSwitcher {Function}
	 * @description
	 * - switches languages for the application, there are 2 variants in the action:
	 * - first case makes a request to the server and receives translations
	 * - second when we look in Redux and if there is already a translation, we take it from the Redux.
	 * */
	const handlerLanguageSwitcher = ({ value }) => {
		let defaultValue = value ? value : shopLanguage
		if (shopId && messages[value] && Object.keys(messages[value]).length) {
			dispatch(setLanguage(value))
		} else {
			dispatch(getLocale(shopId, defaultValue))
		}

		dispatch(getTermsReq(shopId, defaultValue))

		SS.set('language', value)
	}



	//console.log(options, langListOptions)

	return (
		(isLanguageSwitcherDisabled !== 'true') && langListOptions?.length > 1 && (
			<LanguageSelect
				marketFlag={marketFlag}
				onChange={handlerLanguageSwitcher}
				options={langListOptions}
				isSearchable={false}
				value={getShopLanguage(langList(options), locale)}
				onMenuOpen={onMenuOpen}
				isMarketSwitcherDisabled={isMarketSwitcherDisabled}
				isTokenLogin={isTokenLogin}
			/>
		)
	)
}
export default LanguageSwitcher
