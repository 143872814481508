import React from 'react';
import {useSelector} from "react-redux";
import {MainLogoImg, MainLogoImgWrapper, EmptyLogoWrapper} from "../../containers/Main/style";
import {Icon} from "../Icon";
import marysiaLogo from "../../assets/marysia-logo-large-black.png";
import doucheBagsLogo from "../../assets/douchebags_logo_black.png";
import byMalinaLogo from "../../assets/bymalina-logo-white.png";
import eightyEightyFortyEightyLogo from '../../assets/8848_Logo_pos.svg';
import chimiLogo from '../../assets/chimi_logo.svg';
import mustoLogo from '../../assets/musto_logo.svg';
import rdLogo from '../../assets/rd_logo.svg';
import {merchantThemes} from "../Variable";

const MerchantLogo = ({ isOpen }) => {
    const { currentTheme } = useSelector((state) => state.root);
    const themeName = currentTheme && currentTheme.name ? currentTheme.name : '';

    return (
        <div>
            {
                themeName === merchantThemes.woodwood &&
                <MainLogoImgWrapper isOpen={isOpen} themeName={themeName} noImage >
                    <Icon component={'woodWoodLogo'} />
                </MainLogoImgWrapper>
            }
            {
                themeName === merchantThemes.marysia && <MainLogoImg isOpen={isOpen} src={marysiaLogo}/>
            }
            {
                themeName === merchantThemes.douchebags && <MainLogoImg isOpen={isOpen} src={doucheBagsLogo}/>
            }
            {
                themeName === merchantThemes.aycane && <EmptyLogoWrapper/>
            }
            {
                themeName === merchantThemes.royal_design && <MainLogoImg isOpen={isOpen} src={rdLogo} />
            }
            {
                themeName === merchantThemes.bymalina && <MainLogoImg isOpen={isOpen} src={byMalinaLogo} />
            }
            {
                themeName === merchantThemes["8848"] && <MainLogoImg isOpen={isOpen} src={eightyEightyFortyEightyLogo} />
            }
            {
                themeName === merchantThemes.henrylloyd && <EmptyLogoWrapper/>
            }
            {
                themeName === merchantThemes.chimi && <MainLogoImg isOpen={isOpen} src={chimiLogo} />
            }
            {
                themeName === merchantThemes.musto && <MainLogoImg isOpen={isOpen} src={mustoLogo} />
            }
        </div>
    )
}
export default MerchantLogo
